@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url("https://cdn.syncfusion.com/ej2/material.css");

.App {
  background: rgb(255, 0, 228);
  background: radial-gradient(
    circle,
    rgba(255, 0, 228, 1) 0%,
    rgba(212, 51, 16, 1) 84%,
    rgba(255, 0, 95, 1) 100%
  );
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

body {
  margin: 0%;
  padding: 0%;
  font-family: montserrat, arial, verdana;
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
@media (min-width: 800px) {
  .form-container {
    width:75%;
  height: 75%;
  min-height: 600px;
  }
}

.form-container {
  background: linear-gradient(rgba(255,255,255,.93), rgba(255,255,255,.98)),  url(/public/from.png) 50%;
  background-repeat: repeat;

  background-color: white;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;

  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.form-container .header {
  flex: 20%;
  display: grid;
  place-items: center;
}

.form-container .body {
  flex: 60%;
}

.form-container .form-footer {
  flex: 20%;
  display: flex;
  justify-content: center;
}

.form-container .form-footer button {
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: #2386ec;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 0px 5px 10px 5px;
  margin: 5px;
}

.service-type-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service-type-container .form-select select{
  width: 100%;
  height: 50px;
  font-size: 18px;
}

.service-options-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: stretch;
  align-items: left;
  align-content: center;

}

.service-options-container input {
  margin: 5px;
  width: 100%;
  font-size: 20px;
}

.booking-fee-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: stretch;
  align-items: left;
  align-content: center;

}

.booking-details-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  align-content: flex-start;

}

.user-data-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: stretch;
  align-items: left;
  align-content: center;

}

.user-data-container input {
  margin: 5px;
  width: 100%;
  font-size: 20px;
}

#check-box {
  margin: 5px;
  width: 40px;
  height: 40px;
  padding-left: 5px;
  margin-right: 170px;
  font-size: 16px;
}

form-select {
  margin: 5px;
  width: 100px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}

/* PROGRESS BAR */
.progressbar {
  width: 75%;
  height: 20px;
  background-color: white;
}

.progressbar div {
  width: 33.3%;
  height: 100%;
  background-color: #2386ec;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px 8px 0px 0px;
}
@media (max-width: 600px){
  .progressbar  {
    width: 100%
  }
}


.image-upload-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: stretch;
  align-items: left;
  align-content: center;
}

.service-frequency-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: stretch;
  align-items: left;
  align-content: center;
}

.select-date-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: stretch;
  align-items: left;
  align-content: center;
}

.image-upload-container input {
  margin: 5px;
  width: 100%;
  font-size: 20px;
}


form-select {
  margin: 5px;
  width: 100px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}

input,
form-select,select {
  border: 2px solid #2386ec;
  border-radius: 5px;
}

input:focus,
form-select:focus,
select:focus {
  border: 3px solid #2386ec;
}

* {
  box-sizing: border-box;
}

.App {
  width: 70vh;
  margin: 5rem auto;
  display: flex;
  justify-content: space-between;
}

.form-field {
  flex: 2;
}
.output {
  margin-left: 3rem;
  flex: 1;
}
.form-field label,
h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: rgb(84, 84, 84);
}

input, select {
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

input:focus,
select:focus {
  border: 1px solid rgb(0, 208, 255);
}

.services {
  display: flex;
  justify-content: space-between;
}
.first-division {
  display: flex;
  flex-direction: column;
  margin: 0 5px 0.5rem 0;
  width: 100%;
}
button {
  background: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
  padding: 5px 10px;
}
.first-division button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: rgb(0, 208, 255);
  border: 1px solid rgb(0, 208, 255);
}

.second-division button {
  color: red;
  border: 1px solid red;
}


.basic-multi-select{
  min-width: 300px;
}

@media (min-width: 1050px){
.upload-images{
  width: 60%;
}
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 10px 12px;
  cursor: pointer;
}

@media (min-width: 850px){
.cloud-upload{
  width: 5%;
}
}

.gap{
  min-width: 300px;
}