body {
  margin-top: 20px;
}
.block-7 {
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 0;
  overflow: hidden;
  background: #fff;
  -webkit-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 991.98px) {
  .block-7 {
    margin-top: 30px;
  }
}
.block-7 .img {
  height: 250px;
}
.block-7 .heading-2 {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}
.block-7 .price {
  margin: 0;
  padding: 0;
  display: block;
}
.block-7 .price sup {
  font-size: 24px;
  top: -1em;
  color: #b3b3b3;
}
.block-7 .price .number {
  font-size: 60px;
  font-weight: 600;
  color: #000000;
}
.block-7 .excerpt {
  margin-bottom: 0px;
  color: #207dff;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}
.block-7 .label2 {
  text-transform: uppercase;
}
.block-7 .pricing-text,
.block-7 .pricing-text li {
  padding: 0;
  margin: 0;
}
.block-7 .pricing-text li {
  list-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;
}
.block-7 .pricing-text li:nth-child(odd) {
  background: rgba(0, 0, 0, 0.05);
}
.block-7 .pricing-text li span.fa {
  color: #207dff;
}
.block-7 .btn-primary {
  color: #fff;
  text-transform: uppercase;
  font-style: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  width: 40%;
  margin: 0 auto;
}
.block-7 .btn-primary:hover,
.block-7 .btn-primary:focus {
  background: #207dff !important;
  color: #fff;
}
.block-7:hover,
.block-7:focus {
  -webkit-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.11);
}

.house-cleaning-img {
  background-image: url("/public/House-Cleaning-min-300x187.jpg");
  background-repeat: repeat-y;
  background-position: 85%;
  background-size: cover;
}

.office-cleaning-img {
  background-image: url("/public/Office-Cleaning-min-2-1024x683.jpg");
  background-repeat: repeat-y;
  background-position: 85%;
  background-size: cover;
}

.renovation-cleaning-img {
  background-image: url("/public/Renovation-Cleaning-min-2-scaled.jpg");
  background-repeat: repeat-y;
  background-position: 85%;
  background-size: cover;
}

.sanitization-img {
  background-image: url("/public/Sanitization-min-300x200.jpg");
  background-repeat: repeat-y;
  background-position: 85%;
  background-size: cover;
}

.pressure-washing-img {
  background-image: url("/public/Pressure-Washing-min-2-300x222.jpg");
  background-repeat: repeat-y;
  background-position: 85%;
  background-size: cover;
}
.moving-cleaning-img {
  background-image: url("/public/Move-in-Move-out-Cleaning-min-1024x683.jpg");
  background-repeat: repeat-y;
  background-position: 85%;
  background-size: cover;
}

.maid-img {
  background-image: url("/public/Cleaning-Lady..png");
  background-repeat: repeat-y;
  background-position: 50%;
  background-size: contain;
}
